















































































// @ is an alias to /src
import { Component, Vue, Provide } from "vue-property-decorator";
import LayoutHeader from "../layout/LayoutHeader.vue";
import LayoutFooter from "../layout/LayoutFooter.vue";
import KeFu from "../layout/KeFu.vue";
import CardQrCodeDialog from "../../components/CardQrCodeDialog.vue";

@Component({
  components: { LayoutHeader, LayoutFooter,KeFu, CardQrCodeDialog },
})
export default class OrderReturn extends Vue {
  @Provide() siteConfig:any = [];
  @Provide() order_info: any = [];
  @Provide() pay_type: Number = 1;
  @Provide() pay_status_title: String = "";
  @Provide() card_content: String = "";
  // 是否打开卡密二维码页面
  @Provide() dgCardQrCodeVisible: boolean = false;
  // 订单页面内容
  @Provide() viewCardData: any = {
      order_info: [],
      card_list: [],
      card_content: ""
  };
  created() {
    let utils = (this as any).$utils;
    let order_sn = utils.praseStrEmpty(this.$route.query.sn);
    if(order_sn == '') {
      order_sn = utils.praseStrEmpty(this.$route.query.out_trade_no);
    }
    if(order_sn == '') {
      order_sn = utils.praseStrEmpty(this.$route.query.pay_id);
      setTimeout(() => {
        this.getSystemConfig();
        this.loadData(order_sn);
      }, 2000);
    } else {
      this.getSystemConfig();
      this.loadData(order_sn);
    }
  }
  // 获取配置信息
  getSystemConfig() {
    let forms = {
        m: "base.getSystemConfig",
        cat_id: 1
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
        if (res.data.code === 0) {
          this.siteConfig = res.data.data.config;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  toIndex() {
    this.$router.push("/");
  }
  //返回上一页面
  toBack() {
      this.$router.go(-1);
  }
  onCopy(e:any) {
    this.$message({
      showClose: true,
      message: '复制成功',
      type: 'success'
    });
  }
  onError(e:any) {
    this.$message({
      showClose: true,
      message: '复制失败',
      type: 'error'
    });
  }
  // 加载数据
  loadData(order_sn:String) {
    let utils = (this as any).$utils;
    let forms = {
        m: "order.getPayOrder",
        order_sn: order_sn
      };
    (this as any).$ajax.httpPost(
      "/api/api/router",
      forms,
      (res: any) => {
          if (res.data.code === 0) {
              let order_info = res.data.data.order_info;
              this.order_info = order_info;
              if(order_info.pay_status != 1) {
                this.pay_status_title = '订单未支付';
                this.$message({
                  showClose: true,
                  message: '订单未支付',
                  type: 'error'
                });
                setTimeout(() => {
                  this.$router.push('/');
                },2000);
              }
              this.pay_status_title = '订单支付成功';
              // 自动发货
              if(order_info.shipping_type == 1) {
                let card_list = order_info.card_list;
                let card_pwd = '';
                card_list.forEach((item:any,index: number) => {
                  card_pwd += item.card_pass +'\r\n';
                })
                this.card_content = card_pwd;
                this.viewCardData.order_info = order_info;
                this.viewCardData.card_list = card_list;
              }
              // 取得本地缓存订单数据
              if(order_info.member_id < 1) {
                
                let user_order: any = localStorage.getItem("user_order");
                let orders: any = [];
                user_order = utils.praseStrEmpty(user_order);
                if( user_order != ''){
                  orders = JSON.parse(user_order);
                  let existFlg: Number = 0;
                  orders.forEach((item:any,index:Number) => {
                    if(item.orders_sn != order_info.order_sn)
                      existFlg = 1;
                  });
                  if(existFlg == 0){
                    orders.push(order_info);
                  }
                } else {                    
                  orders.push(order_info);
                }
                localStorage.setItem("user_order", JSON.stringify(orders));
              }
          } else {
            this.pay_status_title = res.data.msg;
              this.$message({
                  showClose: true,
                  message: res.data.msg,
                  type: 'error'
              });
              setTimeout(() => {
                this.$router.push('/');
              },2000);
          }
      },
      (err: any) => {
          console.log(err);
      }
    );
  }
  // 查看卡密二维码 
  viewCardQrDialog() {
    this.dgCardQrCodeVisible = true;
  }
  // 关闭卡密二维码页面 
  closeCardQrDialog() {
    this.dgCardQrCodeVisible = false;
  }
}
