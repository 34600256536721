




















// @ is an alias to /src
import { Component, Vue, Prop, Provide } from "vue-property-decorator";

@Component({
  components: {}
})
export default class CardQrCodeDialog extends Vue {
  //属性或参数中使用 ！：表示强制解析（告诉typescript编译器，这里一定有值），常用于vue-decorator中的@Prop
  @Prop(Boolean) dgCardQrCodeVisible!: boolean;
  @Prop(Object) viewCardData!: {
    order_info: any;
    card_list: any;
    card_content: String;
  };
  
  created() {
    console.log('qrcode dialog');
  }
  mounted() {
    console.log('mounted init');
  }
}
